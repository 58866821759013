function AppHeader() {
  return (
    <>  
      <header className="app-header">
        <div className="container container-header">
          <a href="/"><img src="EBRAINS-Curation-Services.png" alt="EBRAINS Curation Services Logo" width="100%" style={{"margin":"10px","display":"block","marginLeft":"auto","marginRight":"auto"}} /></a>
        </div>
      </header>
      <div className="container-gradient"></div>
    </>
  )
}

export default AppHeader;
